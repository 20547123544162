import api from './api'
import utils from './utils'



const adminFetchArea = (page, pageSize, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchArea, {
      page,
      pageSize,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminFetchAreaAll = (token,full = false) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchAreaAll, {
      full
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateAreaStatus = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateArea, {
      id,
      status,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminUpdateArea = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateArea, {
      id,
      name: form.name,
      delivery: form.delivery,
      threshold: form.threshold,
      payment: form.payment,
      deliveryText: form.deliveryText,
      payText: form.payText,
      wx: form.wx,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminCreateArea = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateArea, {
      name: form.name,
      delivery: form.delivery,
      threshold: form.threshold,
      payment: form.payment,
      deliveryText: form.deliveryText,
      payText: form.payText,
      wx: form.wx,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchArea,
  adminUpdateAreaStatus,
  adminUpdateArea,
  adminCreateArea,
  adminFetchAreaAll
}
