import api from './api'
import utils from './utils'



const adminFetchProductByIds = (ids, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchProductByIds, {
      ids,
      status
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminFetchProduct = (page, pageSize, name = '', area = null, spCate = null, status = null, stock = null, price = null, cate = null, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchProduct, {
      page,
      pageSize,
      name,
      area,
      spCate,
      status,
      stock,
      price,
      cate
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminFetchProductByName = (name = '', status,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchProductByName, {
      name,
      status,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminCreateProduct = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateProduct, {
      name: form.name,
      description: form.description,
      imgUrls: form.imgUrls,
      price: form.price,
      stock: form.stock,
      cate: form.cate,
      spCate: form.spCate,
      spPrice: form.spPrice,
      relatedIds: form.relatedIds,
      areaId: form.areaId,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateProduct = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateProduct, {
      id,
      name: form.name,
      description: form.description,
      imgUrls: form.imgUrls,
      price: form.price,
      stock: form.stock,
      cate: form.cate,
      spCate: form.spCate,
      spPrice: form.spPrice,
      relatedIds: form.relatedIds,
      areaId: form.areaId,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const adminUpdateProductStatus = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateProduct, {
      id,
      status,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUploadFileQiniu = (file, token) => {
  const formData = new FormData()
  formData.append('file', file);
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUploadFileQiniu, formData, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const adminRenewProductByIds = (ids, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminRenewProductByIds, {
      ids,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const adminCopyProduct = (areaId, productId, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCopyProduct, {
      areaId, productId
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchProductByIds,
  adminFetchProduct,
  adminUpdateProductStatus,
  adminFetchProductByName,
  adminUpdateProduct,
  adminCreateProduct,
  adminUploadFileQiniu,
  adminRenewProductByIds,
  adminCopyProduct
}
