<template>
  <div>
    <!-- tool bar -->
    <div class="tool-bar">
      <div class="tb-1">
        <div style="display:inline-block;vertical-align:middle ">
          <a-select placeholder="仓库" v-model="searchArea" style="width: 120px" :allowClear="true" @change="onAreaChange"
            @clear="onAreaChange">
            <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle ">
          <a-select placeholder="二级分类（先选仓库）" v-model="searchCate" style="width: 200px" :allowClear="true">
            <a-select-option v-for="(item) in cateSearchList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle ">
          <a-select placeholder="商品状态" v-model="searchStatus" style="width: 120px" :allowClear="true">
            <a-select-option value="on">
              在架
            </a-select-option>
            <a-select-option value="off">
              下架
            </a-select-option>
          </a-select>
        </div>
        <!-- <div style="margin-left:10px;display:inline-block;vertical-align:middle ">
          <a-select placeholder="是否折扣" v-model="searchSpCate" style="width: 120px" :allowClear="true">
            <a-select-option :value="1">
              折扣商品
            </a-select-option>
            <a-select-option :value="0">
              非折扣
            </a-select-option>
          </a-select>
        </div> -->
        <div style="margin-left:10px;display:inline-block;vertical-align:middle ">
          <a-input style="width: 100px" placeholder="库存数量" v-model="searchStock" enter-button @search="onSearch" />
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle ">
          <a-input style="width: 100px" placeholder="商品原价" v-model="searchPrice" enter-button @search="onSearch" />
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle">
          <a-input-search style="width: 300px" placeholder="搜索商品名称或ID" v-model="searchText" enter-button
            @search="onSearch" />
        </div>
      </div>
      <div class="tb-2">
        <a-button @click="onAdd" type="primary">
          创建商品
        </a-button>

        <a-button @click="onRenewSelected" style="margin-left:10px">
          置顶选中商品
        </a-button>
      </div>
    </div>

    <!-- item form for add or edit  -->
    <ItemForm ref="itemForm" @onReset="onReset" @onConfirm="onConfirm" @onSet="onSet" title="商品管理">
      <div class="input-pad">
        <div class="input-label">仓库:</div>
        <a-select placeholder="请选择仓库" v-model="form.areaId" style="width: 200px" :allowClear="false"
          @change="onCreateAreaChange">
          <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="input-pad">
        <div class="input-label">商品名称:</div>
        <a-input v-model="form.name" placeholder="请输入商品名称" />
      </div>
      <div class="input-pad">
        <upload-img ref="uploadImg" @onChangeImg="onChangeImg" @onLoadingImg="onLoadingImg" placeholderText="上传商品图片"
          :totalLength="9" sizeText="商品图片: 尺寸（375 宽 x 300 高）" />
      </div>
      <div class="input-pad">
        <div class="input-label">商品分类:</div>
        <a-select mode="multiple" :filter-option="filterOption" placeholder="必须先选仓库，才可以选分类" v-model="form.cate"
          style="width: 500px" :allowClear="true">
          <a-select-option v-for="(item) in cateList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="input-pad">
        <div class="input-label">商品价格:</div>
        <a-input-number style="width: 200px;" v-model="form.price" :min="0" placeholder="请输入商品价格" />
      </div>
      <div class="input-pad">
        <div class="input-label">库存:</div>
        <a-input-number style="width: 200px;" v-model="form.stock" :min="0" placeholder="请输入库存" />
      </div>
      <div class="input-pad">
        <div class="input-label">是否折扣:</div>
        <a-checkbox :checked="form.spCate" @change="onChangeSpCate">
          勾选，输入折扣商品后价格
        </a-checkbox>
        <a-input-number v-if="form.spCate" style="width: 200px;" v-model="form.spPrice" :min="0"
          placeholder="请输入折扣价格" />
      </div>
      <div class="input-pad">
        <div class="input-label">商品详情（375宽）:</div>
        <v-md-editor v-model="form.description"
          left-toolbar="undo redo clear | h bold italic strikethrough quote | image" :disabled-menus="[]"
          @upload-image="handleUploadImage" height="500px" />
      </div>
      <div class="input-pad">
        <div class="input-label">关联商品:</div>
        <a-select mode="multiple" label-in-value :value="form.relatedIds" placeholder="请搜索添加关联商品" style="width: 100%"
          :filter-option="false" :not-found-content="boolRelated ? undefined : null" @search="onFetchProductByName"
          @change="onChangeProduct">
          <a-spin v-if="boolRelated" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in productList" :key="d.id">
            商品ID：{{ d.areaName }} ，商品ID：{{ d.id }} ，商品名称：{{ d.name }}
          </a-select-option>
        </a-select>
      </div>
    </ItemForm>
    <a-spin :spinning="boolCreate">
      <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :columns="columns" :data-source="data" bordered style="background-color:white">
        <template slot="imgUrls" slot-scope="imgUrls">
          <div class="editable-row-operations">
            <image-tool v-if="imgUrls[0].response != undefined" :imgUrl="imgUrls[0].response.data"></image-tool>
            <div v-else>-</div>
          </div>
        </template>
        <template slot="spCate" slot-scope="text">
          <div>
            <div v-if="text == true"> 折扣商品 </div>
            <div v-else> - </div>
          </div>
        </template>
        <template slot="status" slot-scope="text">
          <div>
            <a-tag v-if="text == 'on'" color="blue"> 正常 </a-tag>
            <a-tag v-else color="red"> 关闭 </a-tag>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div>

            <span>
              <a @click="onRenew(record)">置顶</a>
            </span>

            <span style="margin-left: 10px">
              <a @click="() => onStatus(record)">{{
                record.status == "on" ? "下架" : "上架"
              }}</a>
            </span>
            <span style="margin-left: 10px">
              <a @click="onEdit(record)">编辑</a>
            </span>

            <span style="margin-left: 10px">
              <a @click="onCopy(record)">复制商品</a>
            </span>

            <span style="margin-left: 10px">
              <a @click="onDelete(record)">删除</a>
            </span>
          </div>
        </template>
      </a-table>
    </a-spin>

    <a-modal width="80%" title="复制商品信息" :visible="boolCopy" @ok="onCopyConfirm" @cancel="onCopyReset">
      <a-spin class="block" :spinning="boolLoading">
        <a-select placeholder="选择复制到的仓库" v-model="copyArea" style="width: 220px" :allowClear="true">
            <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
      </a-spin>
    </a-modal>

    <div class="pagination-block">
      <PaginationBar ref="PaginationBar" :currentPageSize="currentPageSize" :total="total" @onChange="onChangePage" />
    </div>
  </div>
</template>

<script>
import PaginationBar from "@/components/PaginationBar.vue";
import ProductServices from "@/services/ProductServices";
import StorageServices from "@/services/StorageServices";
import CateServices from "@/services/CateServices";
import ItemForm from "@/components/itemForm.vue";
import ImageTool from "@/components/imageTool.vue";
import UploadImg from "@/components/uploadImg.vue";

export default {
  components: {
    PaginationBar,
    ItemForm,
    ImageTool,
    UploadImg
  },
  data() {
    return {
      data: [],
      total: 0,
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "所属仓库",
          dataIndex: "areaName",
          key: "areaName",
          width: "10%",
        },
        {
          title: "商品名称",
          dataIndex: "name",
          width: "20%",
          key: "name",
        },
        {
          title: "商品图片",
          dataIndex: "imgUrls",
          key: "imgUrls",
          scopedSlots: { customRender: "imgUrls" },
        },
        {
          title: "价格",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "库存",
          dataIndex: "stock",
          key: "stock",
        },
        {
          title: "是否折扣",
          dataIndex: "spCate",
          key: "spCate",
          width: 150,
          scopedSlots: { customRender: "spCate" },
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      currentPage: 1,
      currentPageSize: 10,
      form: {
        name: "",
        description: "",
        imgUrls: [],
        price: "",
        stock: 0,
        cate: [],
        spCate: false,
        spPrice: null,
        relatedIds: [],
        areaId: undefined
      },
      searchText: "",
      searchStock: null,
      searchPrice: null,
      searchArea: undefined,
      searchSpCate: undefined,
      searchStatus: undefined,
      searchCate: undefined,
      cateList: [],
      cateSearchList: [],
      areaList: [],
      boolCreate: false,
      boolRelated: false,
      productList: [],
      selectedRowKeys: [],
      imgPrevent: false,
      boolCopy: false,
      boolLoading: false,
      copyArea: undefined,
      copyId: null
    }
  },
  created() {
    this.onCreateIndex()
  },
  methods: {

    onCreateIndex() {
      this.boolCreate = true
      this.onFetchArea();
    },

    onAreaChange(e) {
      if (e == undefined) {
        this.cateSearchList = []
        this.searchCate = undefined
        return
      }
      this.onFetchCate(e, true)
    },



    onFetchArea() {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminFetchAreaAll(
        that.$root.token,
      );
      request
        .then((res) => {
          that.areaList = res.data.data
          that.onFetchProduct(this.currentPage, this.currentPageSize);
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    onFetchCate(belongAreaId, search = false) {
      let that = this;
      that.$root.boolLoading = true;
      const request = CateServices.adminFetchSecondCateAll(
        ['on'],
        null,
        belongAreaId,
        that.$root.token
      );
      request
        .then((res) => {
          var temp = []
          res.data.data.forEach(cate => {
            if (cate.type == 'level2') {
              temp.push({
                name: cate.name,
                id: cate.id,
              })
            }
          });
          if (search) {
            that.cateSearchList = temp
          } else {
            that.cateList = temp

          }
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    onFetchProduct(page, pageSize) {
      let that = this;
      that.selectedRowKeys = []
      that.$root.boolLoading = true;
      const request = ProductServices.adminFetchProduct(
        page,
        pageSize,
        that.searchText,
        that.searchArea,
        that.searchSpCate,
        that.searchStatus,
        that.searchStock,
        that.searchPrice,
        that.searchCate,
        that.$root.token
      );
      request
        .then((res) => {
          that.data = res.data.data.data;
          that.data.map((item) => {
            item.key = item.id;
          });
          that.total = res.data.data.total;
        })
        .finally(() => {
          that.$root.boolLoading = false;
          that.boolCreate = false;
        });
    },

    onChangePage(obj) {
      this.currentPage = obj.page;
      this.currentPageSize = obj.pageSize;
      this.onFetchProduct(obj.page, obj.pageSize);
    },

    onStatus(item) {
      let that = this;
      var status = "on";
      if (item.status == "on") {
        status = "off";
      }
      that.$root.boolLoading = true;
      const request = ProductServices.adminUpdateProductStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },


    onAdd() {
      if (this.boolCreate == true) {
        this.$message.info("请等待载入完毕");
        return;
      }
      this.$refs.itemForm.onAdd();
    },
    onEdit(passItem) {
      this.onFetchCate(passItem.belongAreaId)
      this.$refs.itemForm.onEdit(passItem);
    },

    onCheck() {
      if (
        this.form.name.length == 0 ||
        this.form.price.length == 0 ||
        this.form.imgUrls.length == 0 ||
        this.form.cate.length == 0 ||
        this.form.areaId == undefined ||
        this.form.areaId == null
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },
    // when model confirm
    onConfirm(boolEdit) {
      // check form before further operation
      if (!this.onCheck()) {
        return;
      }
      if (this.imgPrevent) {
        this.$message.warn("图片上传中");
        return
      }
      // if it is edit mode or insert mode
      if (!boolEdit) {
        this.onConfirmAdd();
      } else {
        this.onConfirmEdit();
      }
    },
    // when add new record
    onConfirmAdd() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ProductServices.adminCreateProduct(
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          // refresh data
          // that.$root.boolLoading = false;
          // that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },

    // when edit record
    onConfirmEdit() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ProductServices.adminUpdateProduct(
        that.form.id,
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          // that.$root.boolLoading = false;
          // that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },

    // set the data for edit record
    onSet(passItem) {
      this.$root.boolLoading = true;
      // because the uploadImg is inside the dialog, so the refs was generated later

      setTimeout(() => {
        this.form = passItem;
        this.$refs.uploadImg.onSetFileList(passItem.imgUrls);
        this.$root.boolLoading = false;
      }, 500);
    },
    // reset the model data
    onReset() {
      this.form = {
        name: "",
        description: "",
        imgUrls: [],
        price: "",
        stock: 0,
        cate: [],
        spCate: false,
        spPrice: null,
        relatedIds: [],
        areaId: undefined
      };
      var list = []
      this.$refs.uploadImg.onSetFileList(list);
      this.$root.boolLoading = true;
      this.onFetchProduct(this.currentPage, this.currentPageSize);
    },

    onChangeImg(data) {
      this.form.imgUrls = data;
    },
    onLoadingImg(bool) {
      this.imgPrevent = bool;
    },

    onChangeCate(data) {
      this.form.cate = data
      // console.log(data)
    },

    onChangeSpCate(data) {
      // console.log(data)
      this.form.spCate = data.target.checked
    },

    onCreateAreaChange(data) {
      // console.log(data)
      this.form.cate = []
      this.onFetchCate(data)
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },


    onSearch() {
      this.currentPage = 1;
      this.$refs.PaginationBar.onSet(this.currentPage);
      this.onFetchProduct(this.currentPage, this.currentPageSize);
    },

    onCopy(item) {
      this.boolCopy = true
      this.copyId = item.id
    },
    onCopyReset() {
      this.copyArea = undefined
      this.copyId = null
      this.boolCopy = false
    },
    onCopyConfirm() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ProductServices.adminCopyProduct(
        this.copyArea,
        this.copyId,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onCopyReset()
          that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },

    onDelete(item) {
      let that = this;
      var status = "deleted";
      that.$root.boolLoading = true;
      const request = ProductServices.adminUpdateProductStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },


    onFetchProductByName(text) {
      let that = this;
      that.boolRelated = true;
      const request = ProductServices.adminFetchProductByName(
        text,
        ['on'],
        that.$root.token
      );
      request
        .then((res) => {
          that.productList = res.data.data;
          that.productList.map((item) => {
            item.key = item.id;
          });
        })
        .finally(() => {
          that.boolRelated = false;
        });
    },

    onChangeProduct(value) {
      this.form.relatedIds = value
      this.productList = []
      this.boolRelated = false
    },

    handleUploadImage(event, insertImage, files) {
      let that = this;
      that.boolRelated = true;
      that.$root.boolLoading = true;

      const request = ProductServices.adminUploadFileQiniu(
        files[0],
        that.$root.token
      );
      request
        .then((res) => {
          console.log(res)
          // 此处只做示例
          insertImage({
            url: res.data.data,
            desc: 'image',
            // width: 'auto',
            // height: 'auto',
          });
        })
        .finally(() => {
          that.$root.boolLoading = false;

        });
    },

    onRenew(item) {
      let that = this;
      that.$root.boolLoading = true;
      const request = ProductServices.adminRenewProductByIds(
        [item.id],
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },

    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    onRenewSelected() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ProductServices.adminRenewProductByIds(
        this.selectedRowKeys,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },




  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>
